import React, { useState } from 'react';
import axios from 'axios';
import {Button, Container, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "5rem",
    },
    searchField: {
        width: "80%",
        margin: "1rem",
    },
    resultContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2rem",
    },
    product: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem",
        padding: "1rem",
        border: "1px solid #ccc",
        borderRadius: "5px",
    },
    searchInput: {
        textAlign: "center",
    },
});

const Audiobookrecommender = () => {
    const classes = useStyles();
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://api.openai.com/v1/engines/davinci-codex/completions", {
                prompt: question,
                max_tokens: 1024,
                temperature: 0.7,
                n: 1,
                stop: "\n"
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
                }
            });
            console.log(response.data);
            setAnswer(response.data.choices[0].text.trim());
        } catch (error) {
            console.error(error);
            setAnswer("Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.");
        }
    }

    return (
        <>
            <Container maxWidth="md" className={classes.root}>
                <TextField
                    label="Suche nach Hörbüchern"
                    variant="outlined"
                    className={classes.searchField}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}

                    inputProps={{ style: { textAlign: "center" } }}
                />



                <Button variant="contained" color="primary" onClick={handleSearch}>
                    Chat
                </Button>
            </Container>
            <Container>
                <p>{answer}</p>
            </Container>
        </>
    );
}

export default Audiobookrecommender;
