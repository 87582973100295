export const terms = [
    {
        id: 1,
        term: "Hörbuch",
        definition: "Ein Hörbuch ist eine Aufnahme eines Buches oder eines anderen Werks, das laut vorgelesen wird. Hörbücher sind in der Regel als Download oder auf CDs erhältlich und ermöglichen es den Zuhörern, eine Geschichte oder Information zu hören, anstatt sie zu lesen. Hörbücher sind eine großartige Möglichkeit, um unterwegs zu lesen oder eine Geschichte zu genießen, während man sich entspannt.",
    },
    {
        id: 2,
        term: "Sprecher",
        definition: "Ein Sprecher ist eine Person, die ein Hörbuch vorliest. Ein guter Sprecher kann eine Geschichte zum Leben erwecken und das Hörerlebnis verbessern. Es gibt viele verschiedene Arten von Sprechern, darunter professionelle Schauspieler, Autoren und Persönlichkeiten des öffentlichen Lebens.",
    },
    {
        id: 3,
        term: "Streaming",
        definition: "Das Streaming von Hörbüchern ermöglicht es den Benutzern, Hörbücher online zu hören, anstatt sie herunterzuladen oder auf CDs zu kaufen. Das Streaming von Hörbüchern ist besonders praktisch für Menschen, die nicht viel Speicherplatz auf ihren Geräten haben oder keine CDs kaufen möchten. Es gibt viele verschiedene Plattformen, die das Streaming von Hörbüchern anbieten, darunter Audible, Spotify und Apple Music.",
    },
    {
        id: 4,
        term: "Hörspiel",
        definition:
            "Ein Hörspiel ist eine Audioaufnahme eines Theaterstücks, Films oder einer anderen Art von Geschichte, die mit Musik, Geräuschen und verschiedenen Stimmen inszeniert wird. Im Gegensatz zu einem Hörbuch, das normalerweise von einer einzelnen Person vorgelesen wird, wird ein Hörspiel von einer Gruppe von Schauspielern und Schauspielerinnen gesprochen. Oft haben Hörspiele eine komplexe Handlung und sind in mehrere Akte unterteilt. Hörspiele sind eine unterhaltsame und spannende Art, Geschichten zu erleben und eignen sich besonders für Kinder und Jugendliche.",
    },
    {
        id: 6,
        term: "Hörbuch-Download",
        definition:
            "Ein Hörbuch-Download ist der Vorgang des Herunterladens eines Hörbuchs von einem Online-Shop oder einer Streaming-Plattform. Diese Methode bietet den Vorteil, dass das Hörbuch sofort verfügbar ist und jederzeit offline gehört werden kann. Wenn Sie ein Hörbuch herunterladen, benötigen Sie keine Internetverbindung, um es später zu hören."
    },
    {
        id: 7,
        term: "Audible",
        definition:
            "Audible ist ein Anbieter von Hörbüchern und Hörspielen, der von Amazon betrieben wird. Das Unternehmen bietet eine umfangreiche Bibliothek an Hörbüchern in verschiedenen Sprachen und Genres an. Mit einer monatlichen Mitgliedschaft erhalten Sie Zugriff auf eine bestimmte Anzahl von Hörbüchern pro Monat. Audible bietet auch kostenlose Probemonate an, damit Sie das Angebot testen können."
    },
    {
        id: 8,
        term: "Hörbuch-Streaming",
        definition:
            "Beim Hörbuch-Streaming wird das Hörbuch direkt über das Internet abgespielt, ohne dass es zuvor heruntergeladen werden muss. Dies ist eine bequeme Methode, um ein Hörbuch zu hören, da es keine Wartezeiten für den Download gibt. Es ist jedoch eine stabile und schnelle Internetverbindung erforderlich, um das Hörbuch ohne Unterbrechungen zu hören. Viele Hörbuch-Streaming-Dienste bieten auch mobile Apps an, mit denen Sie Hörbücher auf Ihrem Smartphone oder Tablet hören können."
    },
    {
        id: 9,
        term: "Hörbuch-Flatrate",
        definition: "Eine Hörbuch-Flatrate ist ein Abonnement-Service, bei dem Sie Zugang zu einer unbegrenzten Anzahl von Hörbüchern haben. Sie zahlen eine monatliche Gebühr und können so viele Hörbücher herunterladen oder streamen, wie Sie möchten.",
    },
    {
        id: 11,
        term: "Streaming-Abonnement",
        definition: "Ein Streaming-Abonnement ist ein Service, bei dem Sie Zugriff auf eine Bibliothek von Hörbüchern haben und diese jederzeit streamen können, solange Sie ein aktives Abonnement haben. Viele Anbieter bieten verschiedene Abonnement-Modelle an, die unterschiedliche Preise und Funktionen haben."
    },
    {
        id: 12,
        term: "Bookmark",
        definition: "Ein Bookmark ist ein Lesezeichen, das Sie in einem Hörbuch setzen können, um später an der gleichen Stelle weiterzuhören. Diese Funktion ist besonders praktisch, wenn Sie unterwegs sind und das Hörbuch auf verschiedenen Geräten hören möchten."
    },
    {
        id: 13,
        term: "Whispersync for Voice",
        definition: "Whispersync for Voice ist eine Technologie von Amazon, die es ermöglicht, nahtlos zwischen dem Lesen eines eBooks und dem Hören des entsprechenden Hörbuchs zu wechseln, ohne die Stelle zu verlieren. Dabei werden die Hörbuch- und eBook-Version synchronisiert und es kann beispielsweise beim Lesen des eBooks an der gleichen Stelle im Hörbuch weitergehört werden."
    },
    {
        id: 14,
        term: "Sleep-Timer",
        definition: "Ein Sleep-Timer ist eine Funktion, die es ermöglicht, die Wiedergabe eines Hörbuchs nach einer bestimmten Zeit automatisch zu beenden. Dadurch kann man beispielsweise entspannt einschlafen, ohne dass das Hörbuch die ganze Nacht durchläuft."
    },
    {
        id: 15,
        term: "Hörbuch-CD",
        definition: "Eine Hörbuch-CD ist ein physisches Medium, auf dem ein Hörbuch gespeichert ist. Sie können Hörbuch-CDs kaufen oder ausleihen und sie dann auf einem CD-Player oder einem anderen kompatiblen Gerät abspielen. Dies ist eine gute Option für diejenigen, die kein Online-Streaming bevorzugen oder kein unbegrenztes Datenvolumen haben.",
    },
    {
        id: 16,
        term: "Hörbuch-Komplettlesung",
        definition: "Eine Hörbuch-Komplettlesung ist eine Aufnahme eines Buches, bei der der gesamte Text des Buches vorgelesen wird. Im Gegensatz zu gekürzten Versionen enthält eine Komplettlesung alle Kapitel und Passagen des Buches und kann eine längere Hörerfahrung bieten."
    },
    {
        id: 17,
        term: "Hörbuch-Rezension",
        definition: "Eine Hörbuch-Rezension ist eine kritische Bewertung eines Hörbuchs durch einen Leser oder Hörer. Rezensionen können helfen, potenzielle Hörer bei der Entscheidung zu unterstützen, ob ein Hörbuch für sie geeignet ist oder nicht."
    },
    {
        id: 18,
        term: "Hörbuch-Abodienst",
        definition: "Ein Hörbuch-Abodienst ist ein Service, bei dem Hörbücher im Rahmen eines Abonnements heruntergeladen oder gestreamt werden können. Die Abonnementgebühr ermöglicht es dem Hörer, eine bestimmte Anzahl von Hörbüchern pro Monat zu hören."
    },
    {
        id: 19,
        term: "Audible Originals",
        definition: "Audible Originals sind Hörbücher, die exklusiv für das Audible-Programm produziert wurden. Diese Hörbücher sind nur bei Audible erhältlich und bieten oft eine breite Palette an Inhalten, von Originalserien bis hin zu kurzen Geschichten."
    },
    {
        id: 21,
        term: "Hörbuch-Abo",
        definition: "Ein Hörbuch-Abo ist ein Service, bei dem Sie regelmäßig Hörbücher erhalten oder herunterladen können, oft zu einem ermäßigten Preis im Vergleich zum Kauf einzelner Hörbücher.",
    },
    {
        id: 22,
        term: "Hörbuch-Streaming",
        definition: "Hörbuch-Streaming ist ein Service, bei dem Hörbücher direkt über das Internet auf ein Gerät gestreamt werden können, ohne sie zuvor herunterladen zu müssen.",
    },
    {
        id: 23,
        term: "Hörbuch-App",
        definition: "Eine Hörbuch-App ist eine Anwendung, die es Ihnen ermöglicht, Hörbücher auf Ihrem mobilen Gerät zu hören. Diese Apps bieten oft Funktionen wie Lesezeichen, Geschwindigkeitsanpassung und automatische Wiedergabe.",
    },
    {
        id: 24,
        term: "Hörbuch-Player",
        definition: "Ein Hörbuch-Player ist ein Gerät oder eine Software, die speziell für das Abspielen von Hörbüchern entwickelt wurde. Diese Player bieten oft Funktionen wie Lesezeichen, Geschwindigkeitsanpassung und automatische Wiedergabe.",
    },
    {
        id: 25,
        term: "Hörbuch-Podcast",
        definition: "Ein Hörbuch-Podcast ist ein Audio-Podcast, der sich auf Hörbücher konzentriert. Diese Podcasts bieten oft Hörbuchempfehlungen, Interviews mit Autoren und Erzählern sowie Hintergrundinformationen zu Hörbuchproduktionen.",
    },
    {
        id: 26,
        term: "Hörbuch-Kinder",
        definition: "Kinderhörbücher sind eine großartige Möglichkeit, um Kinder in die Welt der Geschichten einzuführen und ihre Fantasie anzuregen. Anders als das Lesen von Büchern, können Kinderhörbücher einfach abgespielt werden, während das Kind bequem im Bett oder im Auto sitzt. Mit der zunehmenden Beliebtheit von Hörbüchern gibt es eine breite Palette an Kinderhörbüchern auf dem Markt, die sich auf alle Altersgruppen und Interessenbereiche konzentrieren.\n" +
            "\n" +
            "Bei der Auswahl eines Kinderhörbuchs ist es wichtig, die Altersgruppe und Interessen des Kindes zu berücksichtigen. Für Kleinkinder sind Hörbücher mit einfachen Geschichten und farbenfrohen Illustrationen ideal. Für ältere Kinder gibt es Hörbücher, die sich auf Abenteuer, Fantasy, Science-Fiction, Detektivgeschichten und vieles mehr konzentrieren.\n" +
            "\n" +
            "Kinderhörbücher können auch pädagogisch wertvoll sein, indem sie Kindern helfen, ihre Sprach- und Hörverständnisfähigkeiten zu verbessern. Hörbücher mit Geschichten, die moralische Werte wie Freundschaft, Familie und Zusammenhalt vermitteln, können Kindern auch wichtige Lebenslektionen beibringen.\n" +
            "\n" +
            "Für Eltern und Erziehungsberechtigte kann die Suche nach dem richtigen Kinderhörbuch eine Herausforderung sein, da es so viele verschiedene Optionen gibt. Es kann hilfreich sein, Bewertungen und Empfehlungen von anderen Eltern zu lesen und sich auf Websites und Plattformen umzusehen, die sich auf Kinderhörbücher spezialisiert haben.\n" +
            "\n" +
            "Auf Hörbuch-Vergleichswebseiten können Eltern eine große Auswahl an Kinderhörbüchern finden und diese nach Kategorien wie Altersgruppe, Thema und Bewertungen filtern. Diese Webseiten bieten auch oft eine Fülle an Informationen zu jedem Hörbuch, einschließlich Autor, Verlag und Sprecher.\n" +
            "\n" +
            "Eltern können auch von den Vorteilen eines Hörbuch-Abonnements profitieren, bei dem sie regelmäßig neue Kinderhörbücher erhalten und Geld sparen können. Einige Hörbuch-Abonnementdienste bieten auch personalisierte Empfehlungen basierend auf den Interessen des Kindes an.\n" +
            "\n" +
            "Insgesamt sind Kinderhörbücher eine wertvolle Ergänzung zum Leben jedes Kindes und können dazu beitragen, die Fantasie, Sprache und das Verständnis von moralischen Werten zu fördern. Mit einer großen Auswahl an Hörbüchern und Hörbuch-Vergleichswebseiten ist es einfach, das perfekte Kinderhörbuch zu finden, das den Interessen und Bedürfnissen des Kindes entspricht.",
    },
    {
        id: 27,
        term: "Metaphern",
        definition: "Metaphern sind ein wichtiges Stilmittel in der Sprache und können auch in Hörbüchern eine große Rolle spielen. Eine Metapher ist eine bildhafte Sprachfigur, die einen Sachverhalt durch eine bildliche oder übertragene Bedeutung verdeutlicht. So kann beispielsweise ein \"Herz aus Stein\" eine Person beschreiben, die keine Emotionen zeigt oder empfindet.\n" +
            "\n" +
            "In unserem Lexikon auf Hörbuch-Vergleich stellen wir Ihnen die wichtigsten Metaphern vor und erklären ihre Bedeutung. Von \"das Herz auf der Zunge tragen\" bis hin zu \"eine Mauer des Schweigens\" - wir zeigen Ihnen, wie Metaphern in verschiedenen Kontexten verwendet werden können.\n" +
            "\n" +
            "Ob Sie nun selbst kreativ schreiben oder einfach nur Ihr Verständnis für Sprache und Ausdruck verbessern möchten, unser Lexikon bietet Ihnen eine umfangreiche Sammlung von Metaphern und deren Bedeutungen. Und wer weiß, vielleicht finden Sie inspirierende Metaphern, die Sie in Ihrem nächsten Hörbuchprojekt verwenden können.\n"
    },
    {
        id: 28,
        term: "Kunst",
        definition: "Kunst ist ein kreativer Ausdruck menschlicher Emotionen und Vorstellungen, der in verschiedenen Formen wie Malerei, Skulptur, Musik, Literatur, Theater und Film dargestellt werden kann. Kunst hat eine lange Geschichte und reicht zurück bis zu den Anfängen der Menschheit. In der Kunst geht es oft um die Schaffung von etwas Neuem, Einzigartigem und Schönem, aber auch um die Darstellung von Realität, Gesellschaft und Kultur. Kunst hat die Fähigkeit, die Betrachter zu inspirieren, zu provozieren und zu bewegen, und wird oft als eine Form der Kommunikation und des kulturellen Austauschs betrachtet. In der heutigen Gesellschaft gibt es eine Vielzahl von Kunstrichtungen und -stilen, die sowohl traditionelle als auch moderne Einflüsse widerspiegeln. Auf unserer Website Hörbuch-Vergleich finden Sie eine umfassende Auswahl an Hörbüchern über Kunst, die Ihnen Einblicke in die verschiedenen Facetten der Kunst geben können." ,
    },


];