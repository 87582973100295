import React, {useState} from "react";
import {Link, Route, Routes} from "react-router-dom";
import Home from "./home"
import Impressum from "./impressum"
import Navigation from "./navigation/navigation"
import Blog from "./blog/blog"
import BlogPost from "./blog/blog-post"
import Lexicon from "./lexikon/lexikon"
import Audiobookrecommender from "./aireco/gptgui"
import Bestseller from "./bestseller/bestseller";
import {Box, Card, CardContent, CardMedia, Container, Grid, Typography} from "@material-ui/core";
import passion from './img/passion.jpg'

/*const imagePaths = [
    "img/bg_1.png",
    "img/bg_2.png",
];*/

function App() {
    const [bgImage, setBgImage] = useState("");

    /*useEffect(() => {
        const randomIndex = Math.floor(Math.random() * imagePaths.length);
        const imagePath = imagePaths[randomIndex];
        setBgImage(`url(${process.env.PUBLIC_URL}/${imagePath})`);
    });*/

    const cardStyle = {
        background: '#ffffff',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex', // Change the flexDirection to column
        gap: '20px',
        justifyContent: 'center',
        maxWidth: '860px',
        margin: '0 auto',  // Automatically center horizontally
    };

    const imageStyle = {
        height: '120px',
    };

    return (
        <div
            style={{
                //backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), ${bgImage}`,
                backgroundColor: "white",
                backgroundSize: "cover",
                backgroundPosition: "center",
                //height: "100vh"
            }}
        >
            <Navigation/>
            <Container style={{marginTop: '40px', paddingBottom: '20px'}}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="lexicon" element={<Lexicon/>}/>
                    <Route path="blog" element={<Blog/>}/>
                    <Route path="blog/:id" element={<BlogPost/>}/>
                    <Route path="impressum" element={<Impressum/>}/>
                    <Route path="airecommendation" element={<Audiobookrecommender/>}/>
                    <Route path="bestseller" element={<Bestseller/>}/>
                </Routes>
            </Container>

            <div style={cardStyle}>
                <img src={passion} style={imageStyle} alt="Passion"/>
                <div>
                    Audasis, der KI-gesteuerte persönliche Assistent, befähigt Sie, Ihre aktuellen Leidenschaften
                    und Vorlieben zu entdecken. Er bietet maßgeschneiderte Anleitungen und sorgfältig ausgewählte
                    Vorschläge für Erlebnisse und Produkte, die mit Ihren einzigartigen Interessen in Einklang
                    stehen. Dabei wird Ihre Privatsphäre und Datensicherheit stets gewährleistet. Unternehmen Sie
                    eine Reise der Selbstentdeckung und Erfüllung mit Audasis als Ihrem vertrauenswürdigen Begleiter
                    bei der Navigation durch die Entscheidungen des Lebens.
                </div>
            </div>

            <footer style={{display: 'fixed', bottom: 0, width: "100%"}}>
                <div className="container"
                     style={{display: "flex", alignItems: "center", justifyContent: "center", padding: "0.5rem"}}>
                    <p style={{margin: 0, paddingRight: "1rem"}}>&copy; 2023 audasis.net. All rights reserved.</p>
                    <Link to="/impressum" target="_blank" rel="noopener noreferrer">Impressum</Link>
                </div>
            </footer>
        </div>
    );
}

export default App;
