import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";
import {ExpandMore as ExpandMoreIcon} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect} from "react";
import clsx from "clsx";
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
    },
    searchField: {
        width: "80%",
        margin: "1rem",
    },
    resultContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2rem",
    },
    product: {
        display: "flex",
        flexDirection: "column",
    },
    searchInput: {
        textAlign: "center",
    },
    expandButton: {
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
});

const ProductCard = ({product}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [expand, setExpand] = React.useState(false);
    const [cheapestProduct, setCheapestProduct] = React.useState();
    const [cheapestAbo, setCheapestAbo] = React.useState();
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        console.log('product');
        console.log(product)
    }, [product]);


    const handleDescriptionExpandClick = () => {
        setExpand(!expand);
    };

    const descriptionText = expand
        ? product.description
        : product.description.slice(0, 250) +
        (product.description.length > 250 ? " ... " : "");

    const expandText = expand ? " ...weniger anzeigen" : "mehr lesen";
    const nonAboProducts = product.aw_deep_links.filter((link) => link.merchant_category !== "Abo");
    const AboProducts = product.aw_deep_links.filter((link) => link.merchant_category === "Abo");

    useEffect(() => {
        if (nonAboProducts.length > 0) {
            const cheapest = nonAboProducts.reduce((acc, cur) => Number(acc.search_price) < Number(cur.search_price) ? acc : cur);
            setCheapestProduct(cheapest);
        }
        if (AboProducts.length > 0) {
            const cheapAbo = AboProducts.reduce((acc, cur) => Number(acc.search_price) < Number(cur.search_price) ? acc : cur);
            setCheapestAbo(cheapAbo);
        }

    }, [nonAboProducts]);

    return (
        <Card className={classes.root}>
            <CardHeader
                title={product.product_name}
                subheader={`Anbieter: ${Array.from(new Set(product.aw_deep_links.map((link) => link.merchant_name)))}`}
            />
            <CardContent>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={product.merchant_image_url}
                        alt={product.product_name}
                        style={{width: "20%", paddingRight: "10px"}}
                    />
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {descriptionText}
                        {!expand && (
                            <span
                                style={{
                                    color: "#2196f3",
                                    cursor: "pointer",
                                }}
                                onClick={handleDescriptionExpandClick}
                            >
                                mehr lesen
                            </span>
                        )}
                        {expand && (
                            <span
                                style={{
                                    color: "#2196f3",
                                    cursor: "pointer",
                                }}
                                onClick={handleDescriptionExpandClick}
                            >
                                {expandText}
                            </span>
                        )}
                    </Typography>
                    <div style={{minWidth: '200px', marginLeft: '5px', textAlign: 'center'}}>
                        <Card sx={{maxWidth: 345}}>
                            <CardContent>
                                <Typography variant='h6'
                                            style={{
                                                backgroundColor: '#5ea758',
                                                color: 'white',
                                                borderRadius: '5px',
                                                padding: '10px'
                                            }}>
                                    Bester Preis
                                </Typography>
                                <br/>
                                {cheapestProduct !== undefined &&
                                    <>
                                        <Button variant="outlined"
                                                color="primary"
                                                startIcon={<AudioFileOutlinedIcon/>}
                                                href={cheapestProduct.aw_deep_link}
                                                target="_blank"
                                                size="large"
                                                style={{fontSize: '25px'}}> {cheapestProduct.search_price.replace(".", ",")} €
                                        </Button>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        > Download / CD</Typography>
                                    </>
                                }
                                {cheapestAbo !== undefined &&
                                    <>
                                        <Button variant="outlined"
                                                color="primary"
                                                startIcon={<SubscriptionsOutlinedIcon/>}
                                                href={cheapestAbo.aw_deep_link}
                                                target="_blank"
                                                size="large" style={{
                                            fontSize: '25px',
                                            marginTop: '10px'
                                        }}> {cheapestAbo.search_price.replace(".", ",")} €
                                        </Button>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        > Abo </Typography>
                                    </>}
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </CardContent>
            {product.aw_deep_links.length > 1 && (
                <>
                    <CardActions disableSpacing>
                        <IconButton
                            className={clsx(classes.expandButton, classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="Alle Anbieter"
                            disableRipple
                        >
                            <ExpandMoreIcon/>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                component="p"
                            >Alle Angebote</Typography>
                        </IconButton>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Grid container spacing={2}>
                                    {
                                        product.aw_deep_links
                                            .sort((a, b) => a.search_price - b.search_price) // Sort by search_price
                                            .map((link, index) => (
                                                <Grid xs={2}>
                                                    <a href={link.aw_deep_link} target="_blank"
                                                       style={{textDecoration: 'none'}}>
                                                        <Card style={{margin: '10px'}} key={index}>
                                                            <CardActionArea>
                                                                <CardContent>
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{}}
                                                                        style={{marginBottom: '5px', maxWidth: '100px'}}
                                                                        image={link.img_url}
                                                                    />
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="textSecondary"
                                                                        component="p"
                                                                    > {link.merchant_name} </Typography>
                                                                    <Typography gutterBottom variant="h5">
                                                                        {link.search_price.replace(".", ",")}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="textSecondary"
                                                                        component="p"
                                                                    >
                                                                        {link.merchant_name == "Audible DE" ? (
                                                                            "Abo (mtl.)"
                                                                        ) : (
                                                                            link.merchant_category
                                                                        )}
                                                                    </Typography>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {link.currency}
                                                                    </Typography>

                                                                </CardContent>

                                                            </CardActionArea>
                                                        </Card>
                                                    </a>
                                                </Grid>
                                            ))}
                                </Grid>
                            </div>
                        </CardContent>
                    </Collapse>
                </>)}
        </Card>
    );
};

export default ProductCard;
