import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Button, Container, Typography} from "@material-ui/core";
import audible from "./img/audible.jpg"
import thalia from "./img/thalia.jpg";

function BlogPost() {
    const {id} = useParams();
    const [article, setArticle] = useState({});
    const url = "https://v2202303197250224059.ultrasrv.de:8443"
    const fetchIndividualArticle = async () => {
        const response = await fetch(url + `/articleInd?id=${id}`);
        const data = await response.json();
        return data
    }

    useEffect(() => {
        const fetchData = async () => {
            const article = await fetchIndividualArticle();
            setArticle(article);
        };

        fetchData();
    }, []);

    return (<Container maxWidth="md">
        <Typography variant="h5" align="center" gutterBottom>
            {article.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
            {article[0] && article[0].content.split('\n').map((paragraph, index) => (<React.Fragment key={index}>
                {paragraph}
                <br/>
            </React.Fragment>))}

        </Typography>
        {article[0]?.deep_link && (<Typography variant="body1" gutterBottom>
            <br/><br/>
            <a href={article[0].deep_link} target="_blank">
                <Button variant="contained" color="success">Reinhören</Button>
            </a>
        </Typography>)}

        <Typography variant="body2" style={{textAlign: "right"}}>
            <em>{`Published ${(new Date(article[0]?.publishing_date)).toLocaleDateString("de-DE")}`}</em>
        </Typography>
        <Button component={Link} to="/blog" style={{marginTop: "1rem"}}>
            Zurück zum Blog
        </Button>
    </Container>);
}

export default BlogPost;
