export const menuItems = [
    {
        name: (
            <span>
                Discover <span role="img" aria-label="Shiny Star">&#127775;</span>
            </span>
        ),
        disabled: false,
        to: "/"
    },
    {
        name: "Blog",
        disabled: false,
        to: "/blog"
    },
    /*{
        name: "Bestseller",
        disabled: false,
        to: "/bestseller"
    },*/
    {
        name: "Lexikon",
        disabled: false,
        to: "/lexicon"
    },

    {
        name: "Club",
        disabled: true,
        to: "/club"
    },
    {
        name: "AI Empfehlung",
        disabled: true,
        to: "/airecommendation"
    }
];
