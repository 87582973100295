import {CircularProgress, Grid, TextField, Typography} from "@material-ui/core";
import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ProductCard from "./productCard";


const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center", // Added to center content vertically
        minHeight: "100vh", // Added to ensure full viewport height
        padding: "5rem",
        background: `url(${process.env.PUBLIC_URL}/img/background.png) no-repeat center center fixed`,
        backgroundSize: "cover",
        opacity: 0.1,
    },
    searchField: {
        width: "80%",
        margin: "1rem",
    },
    resultContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2rem",
    },
    product: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem",
        padding: "1rem",
        border: "1px solid #ccc",
        borderRadius: "5px",
    },
    searchInput: {
        textAlign: "center",
    },
});

function Home() {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState([]);
    const url = "https://v2202303197250224059.ultrasrv.de:8443"
    const timeoutRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async (searchTerm) => {
        try {
            setLoading(true);
            const filters = ["\\(MP3-Download\\)", "Hörbuch,", "Digital,", ", ungekürzt"];

            // Make an HTTP request to the API to fetch products
            const response = await fetch(url + `/products?searchTerm=${encodeURIComponent(searchTerm)}`);
            // Parse the JSON response
            const data = await response.json();
            // Set the fetched data to the products state variable
            const decodedData = data.map((product) => {
                const decodedProduct = {...product};
                decodedProduct.product_name = product.product_name.replace(/\\'/g, "'");
                decodedProduct.product_name = decodedProduct.product_name.replace(/\s*\d+min\.?/, "");
                decodedProduct.product_name = decodedProduct.product_name.replace(new RegExp(filters.join("|"), "gi"), "").trim();
                decodedProduct.product_name = decodedProduct.product_name.replace(/,*$/, "");
                decodedProduct.product_name = decodedProduct.product_name.replace(/\\"/g, '"');
                decodedProduct.merchant_name = product.merchant_name.replace(/\\'/g, "'");

                if (decodedProduct.merchant_name == "Audible DE") {
                    decodedProduct.merchant_category = "Abo"
                } else {
                    decodedProduct.merchant_category = decodedProduct.merchant_category.replace("Hörbuch-Downloads", "Download");
                    decodedProduct.merchant_category = decodedProduct.merchant_category.replace("Hörbuch-Download", "Download");
                    decodedProduct.merchant_category = decodedProduct.merchant_category.replace("Hörbuch", "Audio CD");
                    decodedProduct.merchant_category = decodedProduct.merchant_category.replace("Hörbücher", "Audio CD");

                }
                decodedProduct.description = product.description.replace(/\\'/g, "'");
                decodedProduct.description = decodedProduct.description.replace(/\\"/g, '"');
                decodedProduct.description = decodedProduct.description.replace(/(https?|ftp):\/\/[^\s/$.?#].[^\s]*/g, '');
                decodedProduct.description = decodedProduct.description.replace("Audio sample:", "");
                // Add more properties to decode if needed
                return decodedProduct;
            });
            setProducts(decodedData);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleSearchTermChange = (event) => {
        const {value} = event.target;
        setSearchTerm(value);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            fetchData(value);
        }, 50);
    };

    const groupedProducts = products.reduce((groups, product) => {
        const groupName = product.product_name.toLowerCase();
        if (!groups[groupName]) {
            groups[groupName] = [];
        }
        groups[groupName].push(product);
        return groups;
    }, {});

    const aggregatedProducts = Object.values(groupedProducts).map((group) => {
        const product = group[0]; // Take the first product in the group
        const awDeepLinks = group.map((p) => ({
            merchant_name: p.merchant_name,
            aw_deep_link: p.aw_deep_link,
            search_price: p.search_price,
            currency: p.currency,
            img_url: p.merchant_image_url,
            merchant_category: p.merchant_category
        }));
        return {...product, aw_deep_links: awDeepLinks};
    }).sort((a, b) => b.aw_deep_links.length - a.aw_deep_links.length);

    const handleSearch = () => {
        fetchData();
    };

    return (
        <>
            <div style={{marginTop: "10%"}}>
                <Grid
                    container
                    spacing={2}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Grid xs={12} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={process.env.PUBLIC_URL + "/img/audasis_transparent.png"}
                             alt="audasis - Hörbuch Oase"
                             style={{width: '8%'}}/>
                    </Grid>
                    <Grid xs={12} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>

                        <Typography variant='body' style={{fontFamily: 'Tahoma'}}> audasis - find passion</Typography>
                    </Grid>
                    <Grid xs={12} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <TextField
                            label="Find Passion"
                            variant="outlined"
                            className={classes.searchField}
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                            inputProps={{style: {textAlign: "center"}}}
                            focused
                        />
                    </Grid>
                    {/*      <Grid xs={1}>
                            <Button variant="contained" color="primary" onClick={handleSearch}>
                            Suche
                                </Button>
                            </Grid>
                        */}
                </Grid>
            </div>
            <div className={classes.resultContainer}>
                {loading ? (
                    <CircularProgress/>
                ) : (
                    <>
                        {aggregatedProducts.length > 0 && (
                            <div>
                                {aggregatedProducts.map((product) => (
                                    <ProductCard key={product.id} product={product}/>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default Home;