import React, {useState} from "react";
import {Container, Tab, Tabs, Typography} from "@material-ui/core";
//const cheerio = require('cheerio/lib/cheerio.js');


const apiLinks = ["https://api.audible.de/produktgruppe/bestseller-audio-belletristik", "https://api.goodreads.com/...", "https://api.spotify.com/...", "https://librivox.org/api/..."];

const Bestsellers = () => {
    const [bestsellers, setBestsellers] = useState([]);
    const [value, setValue] = useState(0);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    /*    useEffect(() => {
            const fetchData = async () => {
                const result = await axios.get(
                    "https://www.spiegel.de/kultur/literatur/spiegel-bestseller-hoerbuecher-a-1025528.html"
                );
                console.log(result);
                const $ = cheerio.load(result.data);
                setTitle($('h1[data-testid="headline"]').text());
                setContent($('section[name="articleBody"] > div > p').text());
            };
            fetchData();
        }, []);*/

    /*function handleChange() {
        const fetchData = async () => {
            const result = await axios.get(
                "https://www.spiegel.de/kultur/literatur/spiegel-bestseller-hoerbuecher-a-1025528.html"
            );
            const $ = cheerio.load(result.data);
            setTitle($('h1[data-testid="headline"]').text());
            setContent($('section[name="articleBody"] > div > p').text());
        };
        fetchData();
    }*/
    function handleChange() {
        console.log('tbI');
    }


    return (
        <>
            {console.log(title)}
            <Container maxWidth="md">
                <Typography variant="h4" align="center" gutterBottom>
                    Bestseller Hörbücher
                </Typography>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Spiegel Bestseller"/>
                </Tabs>
                <div>
                    {value === 0 &&
                        bestsellers.map((title) => (
                            <div key={title.id}>
                                <Typography variant="h6">Title</Typography>
                                <Typography variant="subtitle1">
                                    von
                                </Typography>
                                <Typography variant="subtitle2">
                                    Price
                                </Typography>
                                {/*<img src={bestseller.cover} alt={bestseller.title} />*/}
                            </div>
                        ))}
                    {value === 1 && <p>Goodreads Bestsellers</p>}
                    {value === 2 && <p>Spotify Bestsellers</p>}
                    {value === 3 && <p>Librivox Bestsellers</p>}
                </div>
            </Container>
        </>
    );
};

export default Bestsellers;
