import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Container, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { terms } from "./lexiconTerms"; // Hier geht man davon aus, dass das Lexikon in einer Datei namens lexicon.js gespeichert ist.

function Lexicon() {
    const sortedLexiconData = terms.sort((a, b) => a.term.localeCompare(b.term));
    return (
        <div>
            <Container maxWidth="md" xs={{mt:14}}>
                <Typography variant="h3" align="center" gutterBottom>
                    Lexikon
                </Typography>
                    {sortedLexiconData.map((item) => (
                        <Accordion key={item.id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography variant="h5">{item.term}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{item.definition}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </Container>
        </div>
    );
}

export default Lexicon;
