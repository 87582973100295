import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Typography
} from "@material-ui/core";

function Blog() {
    const url = "https://v2202303197250224059.ultrasrv.de:8443"
    const fetchArticle = async () => {
        const response = await fetch(url + `/articles`);
        const data = await response.json();
        return data
    }
    const [articles, setArticles] = useState([])
    const [sortedArticles, setSortedArticles] = useState([])
    const [featuredArticles, setFeaturedArticles] = useState([])
    const [otherArticles, setOtherArticles] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const articles = await fetchArticle();
            setArticles(articles);
            const sortedArticles = articles.sort((a, b) => new Date(b.publishing_date) - new Date(a.publishing_date))
            setSortedArticles(sortedArticles);
            const featuredArticles = sortedArticles.slice(0, 3);
            setFeaturedArticles(featuredArticles);
            const otherArticles = sortedArticles.slice(3)
            setOtherArticles(otherArticles);
        };

        fetchData();
    }, []);

    return (
        <>
            <Typography variant="h3" align="center" gutterBottom>
                Blog
            </Typography>
            <ImageList cols={3} gap={16}>
                {featuredArticles.length === 0 ? (
                    <Typography variant="subtitle1">No featured articles available.</Typography>
                ) : (
                    featuredArticles.map((article) => (
                        <ImageListItem key={article.id}>
                            <Link to={`/blog/${article.id}`}>
                                <img src={article.image} alt={article.title} width="100%"/>
                                <ImageListItemBar title={article.title}/>
                            </Link>
                        </ImageListItem>
                    ))
                )}
            </ImageList>
            <Grid container spacing={3} style={{marginTop: "2rem"}}>
                {otherArticles.map((article) => (
                    <Grid container spacing={3} style={{marginTop: "2rem"}}>
                        {otherArticles.map((article) => (
                            <Grid item xs={12} sm={6} key={article.id}>
                                <Link to={`/blog/${article.id}`} style={{textDecoration: "none"}}>
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            image={article.image}
                                            alt={article.title}
                                            style={{height: '500px'}}
                                        />
                                        <CardContent>
                                            <Typography component="div" variant="h6">
                                                {article.title}
                                            </Typography>
                                            <Typography variant="body1">
                                                {`${article.content.substring(0, 100)}...`}
                                            </Typography>
                                            <Typography variant="body2" style={{textAlign: "right"}}>
                                                <em>
                                                    {`${new Date(article.publishing_date).toLocaleDateString("de-DE")}`}
                                                </em>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>

                ))}

            </Grid>
            <div style={{marginTop: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <a rel="sponsored" href="https://www.awin1.com/cread.php?s=2435493&v=14444&q=368118&r=1282391">
                    <img src="https://www.awin1.com/cshow.php?s=2435493&v=14444&q=368118&r=1282391" border="0"/>
                </a>

            </div>

        </>
    )
        ;
}

export default Blog;
