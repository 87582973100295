import React from "react";
import {Container, Typography} from "@material-ui/core";

function Impressum() {
    return (
        <Container maxWidth="md">
            <Typography variant="h3" align="center" gutterBottom>
                Impressum
            </Typography>
            <Typography variant="h5" gutterBottom>
                Angaben gemäß § 5 TMG:
            </Typography>
            <Typography variant="body1" gutterBottom>
                Markus Wirth
                <br/>
                Flachsröststraße 76
                <br/>
                90475 Nürnberg
            </Typography>
            <Typography variant="h5" gutterBottom>
                Kontakt:
            </Typography>
            <Typography variant="body1" gutterBottom>
                E-Mail: info@audasis.net
            </Typography>
            <Typography variant="h5" gutterBottom>
                Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            </Typography>
            <Typography variant="body1" gutterBottom>
                Markus Wirth
                <br/>
                Flachsröststraße 76
                <br/>
                90475 Nürnberg
            </Typography>
        </Container>
    );
}

export default Impressum;
