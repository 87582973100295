import React, {useState} from "react";
import {Link} from "react-router-dom";
import {AppBar, Button, makeStyles, Toolbar} from "@material-ui/core";
import Logo from "../img/audasis_transparent.png";
import {menuItems} from "./menuItems";
import {createTheme, ThemeProvider} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "rgb(255,255,255,0.1)",
        color: "white",
        boxShadow: "none",
    },
    logo: {
        height: "40px",
        marginRight: "20px",
    },
    navButton: {
        marginRight: "10px",
        fontWeight: ({active}) => active ? 700 : 400,
        "&:hover": {
            backgroundColor: "transparent",
            fontWeight: 700
        },
    },
    navButtonDisabled: {
        marginRight: "10px",
        color: "#cccccc",
        pointerEvents: "none",
        opacity: "0.5",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}));

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ffffff',
        },
    },
});

function Navigation() {
    const [activeLink, setActiveLink] = useState("");
    const classes = useStyles({active: true});

    return (
        <ThemeProvider theme={darkTheme}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <img src={Logo} alt="logo" className={classes.logo}/>
                    {menuItems.map((item, index) => (
                        <Button
                            key={index}
                            component={Link}
                            to={item.to}
                            className={item.disabled ? classes.navButtonDisabled : classes.navButton}
                            onClick={() => setActiveLink(item.to)}
                            style={{fontWeight: activeLink === item.to ? 700 : 400}}
                        >
                            {item.name}
                        </Button>
                    ))}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default Navigation;
